var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Receipt details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_name.id,
      "value": _vm.data.user_name,
      "label": _vm.controls.user_name.label,
      "readonly": _vm.controls.user_name.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.category.id,
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "loading": _vm.controls.category.loading,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('inline-select', {
    ref: _vm.controls.account.id,
    staticStyle: {
      "width": "25em"
    },
    attrs: {
      "id": _vm.controls.account.id,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "loading": _vm.controls.account.loading,
      "async": true,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "changed": _vm.updateSelect
    }
  }), _c('button', {
    staticClass: "btn btn-secondary btn align-self-center ml-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccount();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus",
    staticStyle: {
      "color": "#fff"
    }
  })])], 1)]), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.name.id,
      "value": _vm.data.name,
      "label": _vm.controls.name.label,
      "readonly": _vm.controls.name.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.submission_date.id,
      "value-single": _vm.data.submission_date,
      "label": _vm.controls.submission_date.label,
      "readonly": _vm.controls.submission_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.receipt_date.id,
      "value-single": _vm.data.receipt_date,
      "label": _vm.controls.receipt_date.label,
      "readonly": _vm.controls.receipt_date.readonly,
      "required": _vm.controls.receipt_date.required,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.currency.id,
    attrs: {
      "id": _vm.controls.currency.id,
      "value": _vm.data.currency,
      "label": _vm.controls.currency.label,
      "readonly": _vm.controls.currency.readonly,
      "options": _vm.controls.currency.options,
      "loading": _vm.controls.currency.loading,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount.id,
      "value": _vm.data.amount,
      "label": _vm.controls.amount.label,
      "readonly": _vm.controls.amount.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.data.notes,
      "label": _vm.controls.notes.label,
      "readonly": _vm.controls.notes.readonly,
      "is-text-area": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Related documents")]), _c('hr'), _c('files-container', {
    ref: "files",
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.data.id,
      "readonly": _vm.data.reviewed === true
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push(_vm.$store.getters['router/previousRoute']) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Receipt submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Receipt submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }