<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Receipt details
            <b-spinner v-if="isLoading" small type="grow" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.user_name.id"
                :value="data.user_name"
                :label="controls.user_name.label"
                :readonly="controls.user_name.readonly"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.category.id"
                :ref="controls.category.id"
                :value="data.category"
                :label="controls.category.label"
                :readonly="controls.category.readonly"
                :options="controls.category.options"
                :loading="controls.category.loading"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <div class="d-flex align-items-start">
                <inline-select
                  style="width: 25em"
                  :id="controls.account.id"
                  :ref="controls.account.id"
                  :value="data.account"
                  :label="controls.account.label"
                  :readonly="controls.account.readonly"
                  :options="controls.account.options"
                  :loading="controls.account.loading"
                  :async="true"
                  @async-search="onAccountSearch"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
                <button
                  class="btn btn-secondary btn align-self-center ml-2"
                  type="button"
                  @click="addAccount()"
                >
                  <i class="fa fa-toolbar fa-plus" style="color: #fff" />
                </button>
              </div>
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.name.id"
                :value="data.name"
                :label="controls.name.label"
                :readonly="controls.name.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.submission_date.id"
                :value-single="data.submission_date"
                :label="controls.submission_date.label"
                :readonly="controls.submission_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <!--  data.receipt_date: {{data.receipt_date }} -->
              <inline-date-picker
                :id="controls.receipt_date.id"
                :value-single="data.receipt_date"
                :label="controls.receipt_date.label"
                :readonly="controls.receipt_date.readonly"
                :required="controls.receipt_date.required"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.currency.id"
                :ref="controls.currency.id"
                :value="data.currency"
                :label="controls.currency.label"
                :readonly="controls.currency.readonly"
                :options="controls.currency.options"
                :loading="controls.currency.loading"
                :allow-empty="false"
                :multiple="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.amount.id"
                :value="data.amount"
                :label="controls.amount.label"
                :readonly="controls.amount.readonly"
                :mode="mode"
                :required="true"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <inline-input
                :id="controls.notes.id"
                :value="data.notes"
                :label="controls.notes.label"
                :readonly="controls.notes.readonly"
                :is-text-area="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <h5>Related documents</h5>
          <hr />
          <files-container
            ref="files"
            :module-id="$route.meta.module.id"
            :entity-id="data.id"
            :readonly="data.reviewed === true"
            @loaded="onFileContainerLoad"
          />
        </form>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response
                ? $router.push($store.getters['router/previousRoute'])
                : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Receipt submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Receipt submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
//import Vue from "vue";
import moment from "moment";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";

import FilesContainer from "@/components/FilesContainer";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import { mapState, mapActions } from "vuex";

export default {
  name: "ReceiptSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    InlineDatePicker,
    FormSubmissionActions
  },
  data: function() {
    return {
      mode: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          files: {
            title: "Files"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        user_name: {
          id: "input:user_name",
          label: "User Name",
          changed: false,
          readonly: true
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          loading: false,

          required: true
        },
        category: {
          id: "select:category",
          label: "Category",
          options: [],
          changed: false,
          readonly: false
        },
        submission_date: {
          id: "datepicker:submission_date",
          label: "Submission Date",
          changed: false,
          readonly: true
        },
        receipt_date: {
          id: "datepicker:receipt_date",
          label: "Receipt Date",
          changed: false,
          readonly: false,
          required: true
        },
        currency: {
          id: "select:currency",
          label: "Currency",
          options: [],
          changed: false,
          readonly: false
        },
        amount: {
          id: "input:amount",
          label: "Amount",
          changed: false,
          readonly: false
        },
        name: {
          id: "input:name",
          label: "Name",
          changed: false,
          readonly: false
        },
        notes: {
          id: "input:notes",
          label: "Notes",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        user_id: 0,
        user_name: "",
        submission_date: "",
        receipt_date: "",
        amount: "",
        notes: "",
        category: {
          id: "",
          label: ""
        },
        account: {
          id: "",
          label: ""
        },
        name: "",
        currency: {
          id: "",
          label: ""
        },
        reviewed: false
      },
      olegRaskinImpersonatedUsers: [
        {
          id: 152,
          name: "Boler Abby"
        },
        {
          id: 1060,
          name: "Liebert Thomas"
        },
        {
          id: 158,
          name: "Rabjohn Dusty"
        },
        {
          id: 144,
          name: "Saller Sebastian"
        },
        {
          id: 141,
          name: "Williams Emily"
        },
        {
          id: 1166,
          name: "Kamila	Miekina"
        }
      ]
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created: function() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    initialize: async function() {
      let self = this;

      this.data.id = this.id;

      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
        this.data.id = await this.$api.get("entities/nextid");
      }

      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.$api.get("dictionaries/receipts/categories").then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      this.$api.get("dictionaries/currencies").then(response => {
        this.controls.currency.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        self.fetchData(this.id);
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.submission_date = moment.utc().format("YYYY-MM-DD");

        this.data.currency = {
          id: "120",
          label: "USA, Dollars"
        };

        let ou = this.olegRaskinImpersonatedUsers.find(
          o => o.id === parseInt(this.profile.data.id)
        );

        if (ou) {
          /*show Oleg's name but save under real user_id */

          /*this.data.user_id = 130*/
          this.data.user_id = this.profile.data.id;
          this.data.user_name = "Raskin Oleg";
        } else {
          this.data.user_id = this.profile.data.id;
          this.data.user_name =
            this.profile.data.first_name + " " + this.profile.data.last_name;
        }
      }
    },
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    addAccount: function() {
      /*
                              let routeData = this.$router.resolve({
                                  name: 'Account submission',
                                  params: {
                                      action: "create"
                                  }
                              });
                              window.open(routeData.href, '_blank');
                              */
      this.$router.push({
        name: "Account submission",
        params: {
          action: "create"
        }
      });
    },
    fetchData: async function(id) {
      let self = this;

      return this.$api
        .get(`receipts/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return;

          self.data.category = {
            id: response["Category ID"],
            label: response["Category"]
          };

          if (response["Account ID"]) {
            self.data.account = {
              id: response["Account ID"],
              label: response["Account Name"]
            };
          }

          self.data.currency = {
            id: response["currency_id"],
            label: response["Currency"]
          };

          self.data.id = self.id;
          self.data.user_name = response["User Name"];
          self.data.submission_date = response["Submission Date"];
          self.data.receipt_date = response["Charge Date"];
          //self.data.amount = response["Amount"].padStart(8, "0");
          self.data.amount = response["Amount"];
          self.data.notes = response["Notes"];
          self.data.name = response["Name"];
          self.data.reviewed = response["Reviewed"] === "Yes";

          if (self.data.reviewed) {
            let setReadonly = self.$form.updateControlsState.bind(this);
            setReadonly(true);
          }
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        });
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let self = this;
      let method = "put";
      let url = `receipts/${self.data.id}`;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "receipts";
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;

          let msg = error.message;

          if (!msg) msg = "Error occured. Please reload page!";

          this.$form.makeToastError(msg);

          return Promise.resolve(false);
        });
    },
    updateField(_field, _value, _mode) {
      //console.log("_field, _value, _mode", _field, _value, _mode);
      if (_field == "amount" && _value) {
        var number = Number(_value.replace(",", ".").replace(/[^0-9.-]+/g, ""));
        _value = number;
      }
      let payload = {
        id: this.id,
        self: this,
        url: "receipts",
        field: _field,
        value: _value,
        mode: _mode
      };

      this.$form.updateField2(payload);
    },

    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },

    updateSelect(id, value) {
      this.updateField(id, value);
    }
  },
  watch: {}
};
</script>

<style></style>
